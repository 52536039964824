const translations = {
  en: {
    // Globals
    ticket: 'Ticket',
    tickets: 'Tickets',
    'In Support Of:': 'In Support Of:',
    for: 'for',
    ticketPackages: 'Ticket Packages',
    'Continue to payment': 'Continue to payment',
    'Your Cart': 'Your Cart',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Additional Names': 'Additional Names',
    'Gift Ticket': 'Is this a group purchase or gifted ticket?',
    'Gift Name': 'Enter the names you wish to appear on the ticket(s).',
    Disclaimer:
      'Disclaimer: All tickets issued will contain the same information. Ticket packages cannot be individualized.',
    Address: 'Address',
    City: 'City',
    Province: 'Province',
    'Postal Code': 'Postal Code',
    'Phone Number': 'Phone Number',
    'Email Address': 'Email Address',
    'Confirm Email Address': 'Confirm Email Address',
    'Payment Information': 'Payment Information',
    'Card Number': 'Card Number',
    'Expiry Date': 'Expiry Date',
    CVC: 'CVC',
    Purchase: 'Purchase',
    'CASL Confirmation':
      'I confirm that the information provided is accurate, and acknowledge that my personal information will be collected in accordance with Rafflebox Technologies {privacyPolicy} and {termsOfUse}.',
    Success: {
      thankYou: 'Thank you,',
      inTheDraw: 'You are now in the draw',
      messagePrepend: 'Your ticket numbers were sent to',
      message: "and should arrive within the next 15 minutes. If they don't appear, check your spam/junk folder.",
      saveYourTickets: 'Save your tickets',
      manageYourTickets: 'Manage your tickets',
      manageYourRafflesMessage: 'Download your tickets as a PDF or manage all of your orders by becoming a Player.',
      downloadTicketsAltMessage: 'Download your tickets as a PDF by clicking on the button below',
      weAreGeneratingTickets: 'We are generating your unique tickets for the draw',
      pleaseWait: 'This may take up to a minute',
      yourPurchase: 'Your Purchase',
      total: 'Total',
      'Your details': 'Your details',
      'Back to draw': 'Back to draw',
      'Create Online Order': 'Create Online Order',
      returnToLiveRaffles: 'Return to live raffles page',
      success: 'Success',
      downloadTicketsMessage: 'You can choose to download your tickets as a PDF by clicking on the button below.',
      becomePlayer: 'Become a Player',
      downloadTickets: 'Download Tickets',
      forgetSomething: 'Forget something',
      forgetSomethingMessage:
        'If they do not appear, check your spam/junk folder, or you can download a PDF of the tickets.',
      thisPageHasExpired: 'This page has expired',
      orderConfirmationExpirationMessage:
        'Order confirmations are only saved for seven days. After this time, they are no longer available for download.',
      'Additional Names': 'Additional Names'
    },
    Payment: {
      emailHint: 'We will send you a confirmation email for your purchase',
      provinceHint: 'You must live in the province of the raffle',
      postalHint: 'Postal code must match what is registered with your Credit Card company',
      additionalHint: 'Enter the names you wish to appear on the ticket(s).'
    },
    CartContents: {
      order: 'Order #:'
    },
    AgeCheckMessages: {
      generic: 'I am {age} years or older.',
      Alberta: 'I am {age} years or older and a resident of Alberta.',
      'British Columbia': 'I am {age} years or older and a resident of British Columbia.',
      Manitoba: 'I am {age} years or older and accept the rules.',
      'New Brunswick': 'I am {age} years or older and a resident of New Brunswick.',
      'Newfoundland and Labrador': 'I am {age} years or older and a resident of Newfoundland and Labrador.',
      'Northwest Territories': 'I am {age} years or older and a resident of Northwest Territories.',
      'Nova Scotia': 'I am {age} years or older and a resident of Nova Scotia',
      Nunavut: 'I am {age} years or older and a resident of Nunavut.',
      Ontario: 'I am {age} years or older and accept the rules.',
      'Prince Edward Island': 'I am {age} years or older and a resident of Prince Edward Island.',
      Quebec: 'I am {age} years or older and a resident of Québec.',
      Saskatchewan: 'I am {age} years or older and a resident of Saskatchewan.',
      Yukon: 'I am {age} years or older and a resident of Yukon.'
    },
    TermsAndPolicy: {
      prependMessage: 'By making a purchase, you agree to our',
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use'
    }
  },
  fr: {
    // Globals
    ticket: 'Billet',
    tickets: 'billets',
    'In Support Of:': 'En appui à : ',
    for: 'pour',
    ticketPackages: 'Ensembles de billets',
    'Continue to payment': 'Passer au paiement',
    'Your Cart': 'Votre panier',
    'First Name': 'Prénom',
    'Last Name': 'Nom de famille',
    'Additional Names': 'Noms supplémentaires',
    'Gift Ticket': "S'agit-il d'un achat de groupe ou d'un billet offert en cadeau ?",
    'Gift Name': 'Veuillez entrer les noms que vous souhaitez voir apparaître sur le(s) billet(s).',
    Disclaimer:
      'Avis de non-responsabilité : Tous les billets émis contiendront les mêmes informations. Les forfaits de billets ne peuvent pas être personnalisés individuellement.',
    Address: 'Adresse',
    City: 'Ville',
    Province: 'Province',
    'Postal Code': 'Code postal',
    'Phone Number': 'Tél.',
    'Email Address': 'Adresse électronique',
    'Confirm Email Address': 'Confirmer l’adresse électronique',
    'Payment Information': 'Information de paiement',
    'Card Number': 'Numéro de carte',
    'Expiry Date': "Date d'expiration",
    CVC: 'CVC',
    Purchase: 'Acheter pour',
    Success: {
      messagePrepend: 'Vos numéros de billets vous ont été envoyés à',
      message:
        "et devraient arriver dans les 15 prochaines minutes. S'ils n'apparaissent pas, veuillez vérifier votre dossier spam/courrier indésirable.",
      yourPurchase: 'Votre achat',
      thankYou: 'Merci',
      inTheDraw: 'Vous êtes maintenant dans le tirage au sort',
      saveYourTickets: 'Sauvegardez vos billets',
      manageYourTickets: 'Gérer vos billets',
      manageYourRafflesMessage: 'Téléchargez vos billets au format PDF ou gérez toutes vos commandes en devenant joueur.',
      downloadTicketsAltMessage: 'Téléchargez vos billets au format PDF en cliquant sur le bouton ci-dessous',
      weAreGeneratingTickets: 'Nous générons vos billets uniques pour le tirage',
      pleaseWait: "Cela peut prendre jusqu'à une minute",
      total: 'Total',
      'Your details': 'Vos détails',
      'Back to draw': 'Retourner au tirage',
      'Create Online Order': 'Créer une commande en ligne',
      returnToLiveRaffles: 'Retourner à la page des tirages actifs',
      success: 'Réussite',
      downloadTicketsMessage:
        'Vous pouvez choisir de télécharger une version PDF de vos billets en cliquant sur le buton ci-dessous.',
      becomePlayer: 'Devenir joueur',
      downloadTickets: ' Télécharger des billets',
      forgetSomething: 'Vous avez oublié quelque chose',
      forgetSomethingMessage: `S'ils ne vous parviennent pas, vérifiez votre dossier de pourriels ou dossier poubelle, ou vous pouvez télécharger une version PDF des billets.`,
      thisPageHasExpired: 'Cette page a expiré',
      orderConfirmationExpirationMessage:
        'Les confirmations de commande ne sont conservées que 7 jours. Après cette période, elles ne sont plus disponibles pour télécharger.',
      'Additional Names': 'Noms supplémentaires'
    },
    Payment: {
      emailHint: 'Nous vous enverrons un courriel confirmant votre achat.',
      provinceHint: 'Vous devez vivre dans la province du tirage.',
      postalHint: 'Le code postal doit correspondre à l’information que possède votre compagnie de carte de crédit.',
      additionalHint: 'Saisissez les noms que vous souhaitez voir apparaître sur le(s) billet(s).'
    },
    CartContents: {
      order: 'Votre n° de commande : '
    },
    AgeCheckMessages: {
      generic: "J'ai {age} ans ou plus.",
      Alberta: "J'ai {age} ans ou plus et je suis résident de l'Alberta.",
      'British Columbia': "J'ai {age} ans ou plus et je suis résident de la Colombie-Britannique.",
      Manitoba: "J'ai {age} ans ou plus et j'accepte les règles.",
      'New Brunswick': "J'ai {age} ans ou plus et je suis résident du Nouveau-Brunswick.",
      'Newfoundland and Labrador': 'Je suis âgé de {age} ans ou plus et je réside à Terre-Neuve-et-Labrador.',
      'Northwest Territories': 'Je suis âgé de {age} ans ou plus et je réside dans les Territoires du Nord-Ouest.',
      'Nova Scotia': "J'ai {age} ans ou plus et je suis résident de la Nouvelle-Écosse",
      Nunavut: "J'ai {age} ans ou plus et je suis résident du Nunavut.",
      Ontario: "J'ai {age} ans ou plus et j'accepte les règles.",
      'Prince Edward Island': "Je suis âgé de {age} ans ou plus et je réside sur l'Île-du-Prince-Édouard.",
      Quebec: "J'ai {age} ans ou plus et je réside au Québec.",
      Saskatchewan: "J'ai {age} ans ou plus et je suis résident de la Saskatchewan.",
      Yukon: "J'ai {age} ans ou plus et je réside au Yukon."
    },
    TermsAndPolicy: {
      prependMessage: 'Le fait de cliquer sur Achat indique que vous acceptez les dispositions de la',
      privacyPolicy: 'déclaration de confidentialité',
      termsOfUse: "conditions d'utilisation"
    },
    'CASL Confirmation': `Je confirme que les informations fournies sont excates, et je reconnais que mes informations personnelles seront collectées conformement á la {privacyPolicy} et aux {termsOfUse} de Rafflebox Technologies.`
  }
};

export default translations;
