<template>
  <div class="cart-contents mt-3 mb-4" :class="{ 'success-summary': $route.name === 'Success' }">
    <div class="raffle-title">
      <h2>Order Summary</h2>
      <CircleNav v-if="showMultiAddonFlow && $route.name !== 'Payment' && $route.name !== 'Success'" />
    </div>
    <InSupport />

    <b-row v-if="total !== 0">
      <b-col class="padding-wrapper">
        <b-row v-if="!isMultiEvent">
          <b-col>
            <h3 v-if="total !== 0" class="ticket-type-header">{{ title }}</h3>
            <div v-for="raffle in sortedTickets" :key="raffle.id">
              <p v-if="raffle.orderNumber && raffle.tickets.length" class="order-number">
                {{ $t('CartContents.order') }} {{ raffle.orderNumber }}
              </p>
              <div v-if="raffle.tickets.length">
                <ul>
                  <li v-for="ticket in raffle.tickets" :key="ticket.id" class="d-flex">
                    <p v-if="$store.state.specialEvent && raffle.raffleType === 1" class="amount-of-tickets">
                      {{
                        `${ticket.quantity} x ${ticket.numTickets} ${
                          ticket.numTickets > 1 ? $t('tickets') : $t('ticket')
                        }`
                      }}
                    </p>
                    <p v-else class="amount-of-tickets">
                      {{
                        `${ticket.quantity} x ${ticket.numTickets} ${
                          ticket.numTickets > 1 ? $t('tickets') : $t('ticket')
                        }`
                      }}
                    </p>
                    <span class="dotted-line"></span>
                    <span class="no-wrap">
                      {{
                        $i18n.locale === 'fr'
                          ? formatCurrency((ticket.priceCents * ticket.quantity) / 100, { precision: 0, locale: 'fr' })
                          : formatCurrency((ticket.priceCents * ticket.quantity) / 100, { precision: 0 })
                      }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>

        <div v-else>
          <b-row v-for="raffle in sortedTickets" :key="raffle.id">
            <b-col>
              <h4 class="ticket-type-header">{{ raffle.eventName }}</h4>
              <p v-if="raffle.orderNumber && raffle.tickets.length" class="order-number">
                Order #: {{ raffle.orderNumber }}
              </p>
              <div v-if="raffle.tickets.length">
                <ul>
                  <li v-for="ticket in raffle.tickets" :key="ticket.id" class="d-flex">
                    <p v-if="$store.state.specialEvent && raffle.order === 1" class="amount-of-tickets">
                      {{
                        `${ticket.quantity} x ${ticket.numTickets} ${
                          ticket.numTickets > 1 ? $t('tickets') : $t('ticket')
                        }`
                      }}
                    </p>
                    <p v-else class="amount-of-tickets">
                      {{
                        `${ticket.quantity} x ${ticket.numTickets} ${
                          ticket.numTickets > 1 ? $t('tickets') : $t('ticket')
                        }`
                      }}
                    </p>
                    <span class="dotted-line"></span>
                    <span class="no-wrap">
                      {{
                        $i18n.locale === 'fr'
                          ? formatCurrency((ticket.priceCents * ticket.quantity) / 100, { precision: 0, locale: 'fr' })
                          : formatCurrency((ticket.priceCents * ticket.quantity) / 100, { precision: 0 })
                      }}
                    </span>
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul>
                  <li><em>No tickets selected</em></li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row v-if="!acceptDonations && donationAmount > 0">
          <b-col>
            <h4 class="ticket-type-header">Donation</h4>
            <ul>
              <li class="d-flex">
                <p class="amount-of-tickets">Amount</p>
                <span class="dotted-line"></span>
                <span class="no-wrap">
                  {{
                    $i18n.locale === 'fr'
                      ? formatCurrency(donationAmount / 100, { precision: 0, locale: 'fr' })
                      : formatCurrency(donationAmount / 100, { precision: 0 })
                  }}
                </span>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row v-if="isGoldrush">
          <b-col>
            <p v-if="total > 0" class="text-right">{{ `For ${pluralize(numDraws, 'draw')}` }}</p>
          </b-col>
        </b-row>
        <b-row v-if="totalProcessingFee">
          <b-col cols="12">
            <div class="small text-right mt-3 mb-1">
              Processing Fee ({{ $store.state.event.processingFeePercent }}%)
              {{ formattedProcessingFee }}
            </div>
          </b-col>
        </b-row>
        <Donation v-if="acceptDonations" />
        <b-row>
          <b-col cols="12">
            <h5 class="cart-total text-right mt-1 mb-1">
              Total
              {{ formattedTotal }}
            </h5>
            <p v-if="isGoldrush && !error" class="text-right mb-0">
              <small>
                Paid up to and including draw #{{ Number(eventNumber) + Number(numDraws) - 1 }} <br />
                {{ formatDateTime(nextDrawDate, { format: 'dateTime' }) }}
              </small>
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { parseISO, addDays } from 'date-fns';
import { EventCheckoutType, Event } from '@/lib/schema/event.schema';
import { ShoppingCartItem } from '@/model/shopping-cart';
import InSupport from '@/components/InSupport.vue';
import ProjectMixin from '@/project-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { formatCurrency } from '@rafflebox-technologies-inc/rafflebox-lib';
import Donation from '@/components/Donation.vue';
import CircleNav from '@/components/CircleNav.vue';
import config from '@/config';

interface SortedTickets {
  [key: string]: {
    raffleType: number;
    order: number;
    orderNumber: string | null;
    eventName: string;
    tickets: ShoppingCartItem[];
  };
}

@Component({
  components: {
    InSupport,
    Donation,
    CircleNav
  }
})
export default class CartContents extends Mixins(ProjectMixin) {
  @Prop({ required: false, default: null })
  title!: string;

  @Prop({ required: false, default: null })
  orders!: [];

  @Prop({ required: false, default: null })
  draws!: number;

  @Prop({ required: false, default: null })
  lastDrawDate!: string;

  @Prop({ required: false, default: false })
  error!: boolean | string;

  eventName: string = this.$store.state.event.name;
  eventNumber: string = this.$store.state.event.drawNumber;
  prizeRaffleId: string = this.$store.state.event.id;
  addOnRaffleId: string = this.$store.state.addon.id;
  addOns: Event[] = this.$store.state.event.childEvents;

  sortedTickets: SortedTickets = {
    prizeTickets: {
      raffleType: this.$store.state.event.category,
      order: 1,
      orderNumber: '',
      eventName: '',
      tickets: [] as ShoppingCartItem[]
    }
  };

  get nextDrawDate() {
    if (this.lastDrawDate) {
      return parseISO(this.lastDrawDate);
    }

    if (this.numDraws === 1) {
      return parseISO(this.$store.state.event.drawDate);
    }

    const drawDate = parseISO(this.$store.state.event.drawDate);
    const daysPerDraw = this.$store.state.goldrushMasterEvent.daysPerDraw * (this.numDraws - 1);
    const nextDraw = addDays(drawDate, daysPerDraw);

    return nextDraw;
  }

  get masterEvent() {
    return this.$store.state.goldrushMasterEvent;
  }

  get isMultiEvent() {
    return this.$store.state.category === EventCheckoutType.multiraffle;
  }

  get isGoldrush() {
    return this.$store.state.category === EventCheckoutType.goldrush;
  }

  get numDraws() {
    return this.shoppingCart.numDraws;
  }

  get totalProcessingFee() {
    return this.shoppingCart.totalProcessingFeeCents() / 100;
  }

  get total() {
    return this.shoppingCart.totalCartWithProcessingFee();
  }

  get showMultiAddonFlow() {
    return config.SHOW_MULTI_ADD_ON_FLOW_ORGS.includes(this.$store.state.event.organizationId);
  }

  get formattedProcessingFee() {
    if (this.$i18n.locale === 'fr') {
      return formatCurrency(this.totalProcessingFee, {
        precision: 2,
        locale: 'fr'
      });
    } else {
      return formatCurrency(this.totalProcessingFee, {
        precision: 2
      });
    }
  }

  get formattedTotal() {
    if (this.$i18n.locale === 'fr') {
      return formatCurrency(this.total, {
        precision: 2,
        locale: 'fr',
        currency: this.$store.state.event.currency.toUpperCase()
      });
    } else {
      return formatCurrency(this.total, { precision: 2, currency: this.$store.state.event.currency.toUpperCase() });
    }
  }

  get acceptDonations() {
    if (this.$store.state.event.settings.donationEnabled && this.$route.name !== 'Success') {
      if (this.$store.state.category === 'SINGLE') {
        return true;
      }

      if (this.$store.state.category === 'MULTIRAFFLE' && this.$route.name !== 'Payment') {
        return true;
      }
    }
    return false;
  }

  get donationAmount() {
    return this.shoppingCart.totalDonationCents();
  }

  created() {
    this.setOrders();
    this.sortTickets();
  }

  cartItems() {
    return this.shoppingCart.items();
  }

  sortTickets() {
    this.sortedTickets.prizeTickets.tickets = this.cartItems().filter((ticketPackage) => {
      return ticketPackage.eventId === this.prizeRaffleId;
    });
    this.sortedTickets.prizeTickets.eventName = this.eventName;

    const addOnTickets = this.cartItems().filter((ticketPackage) => {
      return ticketPackage.eventId !== this.prizeRaffleId;
    });

    addOnTickets.forEach((ticketPackage) => {
      const addOnEvent = this.addOns.find((addOn) => addOn.id === ticketPackage.eventId);
      const eventName = addOnEvent ? addOnEvent.name : '';
      if (!this.sortedTickets[ticketPackage.eventId]) {
        this.sortedTickets[ticketPackage.eventId] = {
          raffleType: 0,
          order: 0,
          eventName: eventName,
          tickets: [],
          orderNumber: null
        };
      }
      this.sortedTickets[ticketPackage.eventId].tickets.push(ticketPackage);
    });

    this.setOrders();
  }

  setOrders() {
    if (this.orders !== null) {
      this.orders.forEach((order: any) => {
        if (order.eventId === this.prizeRaffleId) {
          this.sortedTickets.prizeTickets.orderNumber = order.orderId.substring(0, order.orderId.length - 2);
        } else if (this.sortedTickets.addOnTickets) {
          this.sortedTickets.addOnTickets.orderNumber = order.orderId.substring(0, order.orderId.length - 2);
        }
      });
    }
  }

  setTicketTypeHeader(category: string) {
    let title;
    if (category === 'Raffle') {
      title = 'Prize Raffle';
    } else {
      title = '50/50';
    }

    return title;
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $heading;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 1.5rem;
}

ul {
  list-style: none;
}

.title {
  margin-bottom: 2rem;
}

.sub-title {
  font-family: $heading;
  font-size: 1.25rem;
  font-weight: 600;
}

.raffle-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-contents {
  width: 100%;
  margin: auto;
  font-family: $body;
  font-size: 1.25rem;

  .ticket-type-header {
    font-weight: normal;
    font-family: $heading;
  }

  .order-number {
    font-size: 1rem;
    margin-top: -5px;
    margin-bottom: 10px;
    color: #0a0a0b;
  }

  ul {
    padding-left: 15px;
  }

  .amount-of-tickets {
    margin-bottom: 0;
    white-space: nowrap;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .dotted-line {
    min-width: 4rem;
    width: 100%;
    border-bottom: 1px dashed;
    margin: 0 5px 3px 5px;
  }

  .cart-total {
    font-family: $heading;
    font-weight: bold;
    font-size: 1.75rem;
  }
}

.success-summary {
  .title {
    font-size: 2rem;
  }
}

// iPad Portrait
@media screen and (min-width: 48rem) {
  h2 {
    font-size: 3rem;
    margin: 2rem 0 3rem;
  }

  h4 {
    font-size: 2rem;
  }
}
</style>
