// https://docs.getunleash.io/reference/sdks/javascript-browser
import { UnleashClient } from 'unleash-proxy-client';
import config from '../config';
import { SessionUser } from '@rafflebox-technologies-inc/auth-service-sdk';

const unleashConfig = {
  url: 'https://us.app.unleash-hosted.com/usff0003/api/frontend',
  clientKey: config.UNLEASH_CLIENT_KEY,
  appName: 'checkout',
  refreshInterval: 0 // never refresh without reload of page
};

let client: UnleashClient;

/**
 * All keys in Unleash would be the values of this object only.
 */
export const UnleashKeys = {
  Phase1: 'Phase-1',
  Phase2: 'Phase-2',
  Phase3: 'Phase-3'
};

const formatContext = (user?: SessionUser) => {
  if (!user) {
    return {};
  }
  const roles: { [key: string]: boolean } = user.roles.reduce((map: { [key: string]: boolean }, role) => {
    const key = role.replace(/\s+/g, '');
    map[key] = true;
    return map;
  }, {});

  return {
    userId: user.id,
    organizationId: user.organizationUuid,
    email: user.email,
    ...roles
  };
};

export const initializeUnleash = async (user?: SessionUser): Promise<void> => {
  console.log('[Unleash] Initializing Unleash client');
  client = new UnleashClient(unleashConfig);
  client.start();

  return new Promise((resolve) => {
    client.on('ready', () => {
      // Required to prevent a race check where the user context is not yet set on refresh
      if (user) {
        const context = formatContext(user);
        client.updateContext(context);
      }

      // eslint-disable-next-line
      console.log('[Unleash] Client is ready');
      resolve();
    });
  });
};

export const updateUnleashContext = (user?: SessionUser) => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return;
  }

  const context = formatContext(user);

  client.updateContext(context);
  console.log('[Unleash] Updated context with user');
};

export const unleashFeatureEnabled = (key: string): boolean => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return false;
  }

  const isEnabled: boolean = client.isEnabled(key);

  return isEnabled;
};
